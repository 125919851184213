/*----------------------------------------*/
/*  04. FEATURES CSS START
/*----------------------------------------*/

.features {
  &__area {
    background-color: $grey-12;
  }
  &__inner {
    @include box-shadow(0px 50px 70px 0px #492b173d);
    @include border-radius(10px);
    @media #{$xs} {
      margin-top: 100px;
    }
  }

  &__item {
    position: relative;
    border-right: 1px solid $border-5;
    z-index: 1;
    min-height: 300px;

    &:hover {
      & .features__content {
        top: 40%;
      }
      & .features__btn {
        opacity: 0;
        visibility: visible;
        opacity: 1;
      }
      & .features__thumb {
        &::after {
          opacity: 0;
        }
      }
    }
    &-2 {
      position: relative;
      @include border-radius(10px);
      padding: 45px 40px;
      border-right: none;
      @include box-shadow(0px 20px 50px 0px #492b1739);
      &:hover {
        & .features__thumb-2 {
          opacity: 1;
        }
        & .features__content-2 {
          & h3 {
            color: $white;
          }
          & p {
            color: $white;
          }
        }
        & .features__icon {
          & i {
            color: $white;
          }
        }
        & .link-btn {
          color: $white;
        }
      }
    }
  }
  &__thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include background();
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: -moz-linear-gradient(-180deg, #e9e6e1 0%, #492b17 100%);
      background-image: -webkit-linear-gradient(
        -180deg,
        #e9e6e1 0%,
        #492b17 100%
      );
      background-image: -ms-linear-gradient(-180deg, #e9e6e1 0%, #492b17 100%);
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #492b17;
      opacity: 0.8;
    }
    &-2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include background();
      @include transition(0.3s);
      opacity: 0;
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: -moz-linear-gradient(60deg, #e9e6e1 0%, #492b17 70%);
        background-image: -webkit-linear-gradient(
          60deg,
          #e9e6e1 0%,
          #492b17 70%
        );
        background-image: -ms-linear-gradient(60deg, #e9e6e1 0%, #492b17 70%);
        background-image: linear-gradient(60deg, #e9e6e1 0%, #492b17 70%);
        opacity: 0.8;
      }
    }
  }

  &__icon {
    margin-bottom: 20px;
    & i {
      font-size: 30px;
      color: $white;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
    }
    &-2 {
      margin-bottom: 30px;
      & i {
        font-size: 34px;
        color: $theme-color;
      }
    }
  }
  &__content {
    position: absolute;
    top: 55%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    width: 100%;
    height: auto;
    @include transition(0.3s);
    padding: 0 15px;
    & h3 {
      font-size: 16px;
      color: $white;
      margin: 0 auto 15px;
      max-width: 160px;
    }
    &-left {
      & p {
        margin-bottom: 65px;
        line-height: 30px;
      }
    }
    &-right {
      @media #{$md} {
        margin-top: 50px;
      }
      @media #{$sm} {
        margin-top: 50px;
      }
      @media #{$xs} {
        margin-top: 50px;
      }
    }
    &-2 {
      position: relative;
      z-index: 1;

      & h3 {
        font-size: 22px;
        font-weight: 900;
      }
      & p {
        margin-bottom: 25px;
      }
    }
  }
  &__btn {
    visibility: hidden;
    opacity: 0;
    @include transition(0.3s);
    & a {
      color: $white;
    }
  }
}
