/*----------------------------------------*/
/*  20. PAGE TITLE CSS START
/*----------------------------------------*/

.page {
  &__title {
    min-height: 500px;
    @include background();
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: -moz-linear-gradient(60deg, #e9e6e1 0%, #492b17 100%);
      background-image: -ms-linear-gradient(60deg, #e9e6e1 0%, #492b17 100%);
      background-image: -webkit-linear-gradient(
        60deg,
        #e9e6e1 0%,
        #492b17 100%
      );
      background-image: linear-gradient(60deg, #e9e6e1 0%, #492b17 100%);
      opacity: 0.9;
    }
    &-2 {
      min-height: 700px;
      @media #{$sm} {
        min-height: 500px;
      }
      @media #{$xs} {
        min-height: 500px;
      }
    }
    &-content {
      & h2 {
        font-size: 70px;
        color: $white;
        @media #{$sm} {
          font-size: 60px;
        }
        @media #{$xs} {
          font-size: 50px;
        }
      }
      & .breadcrumb {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        margin-bottom: 0;
        &-item {
          color: $white;
          font-size: 18px;
          &:hover {
            color: $white;
          }
          &.active {
            padding-left: 14px;
            &:hover {
              color: $white;
            }
            &::before {
              content: "|";
              color: $white;
              padding-right: 14px;
            }
          }
        }
      }
      & span {
        color: $white;
        opacity: 0.5;
        display: inline-block;
        margin-bottom: 15px;
      }
      & h3 {
        font-size: 70px;
        color: $white;
        @media #{$sm} {
          font-size: 50px;
        }
        @media #{$xs} {
          font-size: 30px;
        }
      }
    }
  }
}
