/*----------------------------------------*/
/*  10. BRAND CSS START
/*----------------------------------------*/

.brand {
  &__area {
    background-color: $grey-12;
  }
  &__subtitle {
    & p {
      font-size: 16px;
      padding-right: 120px;
      margin-top: 30px;
      line-height: 30px;
    }
  }
  &__item {
    margin-bottom: 90px;
    text-align: center;
    & img {
      width: 150px;
      display: inline-block !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .brand {
    &__item {
      margin-bottom: 90px;
      text-align: center;
      display: block;

      & img {
        width: 80px;
        display: block;
      }
    }
  }
}
