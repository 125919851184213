/*----------------------------------------*/
/*  03. SLIDER CSS START
/*----------------------------------------*/
.bg-image {
  background-image: url("../img/slider/02/slider-1.jpg");
}

@media (max-width: 991px) {
  .bg-image {
    background-image: url("../img/slider/02/logo.png");
  }
}

.single-slider {
  position: relative;
  @include background();

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(60deg, #492b17 100%, #e9e6e1 0%);
    background-image: -ms-linear-gradient(60deg, #492b17 100%, #e9e6e1 0%);
    background-image: -webkit-linear-gradient(60deg, #492b17 100%, #e9e6e1 0%);
    background-image: linear-gradient(60deg, #492b17 100%, #e9e6e1 0%);
    opacity: 0.9;
  }

  &-2 {
    &::before {
      display: none;
    }
    &.slick-active {
      & .slider__content-2 {
        &::before {
          width: 250px;
          @media #{$xs} {
            width: 200px;
          }
        }
      }
    }
  }
}

.slick-active .single-slider-2 .slider__content-2::before {
  width: 250px;
}

.slider {
  &__area2 {
    @media #{$lg} {
      display: none;
    }
    @media #{$md} {
      display: none;
    }

    @media #{$xs} {
      display: none;
    }
  }
  &__area {
    position: relative;
    & .slick-dots {
      display: none;
    }
    &-2 {
      & .slick-dots {
        display: inline-block !important;
        position: absolute;
        bottom: 30px;
        left: 140px;
        & li {
          display: inline-block;
          &.slick-active {
            & button {
              background: $white;
            }
          }
          & button {
            font-size: 0;
            width: 14px;
            height: 14px;
            background: transparent;
            border: 2px solid rgba($color: $white, $alpha: 0.3);
            display: inline-block;
            @include border-radius(50%);
            margin-right: 10px;
          }
        }
      }
    }
  }
  &__height {
    min-height: 950px;

    @media #{$xs} {
      min-height: 600px;
    }
    &-2 {
      @media #{$md} {
        min-height: 800px;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    & > span {
      color: $grey-3;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 17px;
    }
    & h1 {
      font-size: 70px;
      color: $white;
      font-weight: 900;
      margin-bottom: 38px;
      @media #{$md} {
        font-size: 50px;
      }

      @media #{$xs} {
        font-size: 30px;
      }
    }
    &-2 {
      position: relative;
      & > span {
        padding: 4px 10px;
        color: #e6e6e6;
        background: rgba($color: $white, $alpha: 0.1);
        @include border-radius(6px);
        display: inline-block;
        margin-bottom: 10px;
        font-size: 16px;
      }
      & h1 {
        margin-bottom: 15px;
        @media #{$laptop} {
          font-size: 60px;
        }
        @media #{$lg} {
          font-size: 60px;
        }
      }
      & p {
        color: #e6e6e6;
        margin-bottom: 45px;
      }
      &::before {
        position: absolute;
        content: "";
        left: -100px;
        top: -115px;
        width: 0;
        height: 600px;
        background-image: -moz-linear-gradient(
          -120deg,
          #e9e6e1 0%,
          #492b17 100%
        );
        background-image: -webkit-linear-gradient(
          -120deg,
          #e9e6e1 0%,
          #492b17 100%
        );
        background-image: -ms-linear-gradient(
          -120deg,
          #e9e6e1 0%,
          #492b17 100%
        );
        background-image: linear-gradient(-120deg, #e9e6e1 0%, #492b17 100%);
        opacity: 0.8;
        z-index: -1;
        border-top-left-radius: 100px;
        @media #{$lg} {
          top: -90px;
          height: 500px;
        }
        @media #{$md} {
          top: -90px;
          height: 500px;
        }

        @media #{$xs} {
          top: -65px;
          height: 400px;
          left: auto;
          right: 0px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          -moz-transform: scaleX(-1);
          -ms-transform: scaleX(-1);
          -o-transform: scaleX(-1);
        }
      }
    }
    &-3 {
      &::before {
        display: none;
      }
      & p {
        display: inline-block;
        margin-bottom: 40px;
      }
    }
    &-4 {
      padding-right: 65px;
      & p {
        margin-bottom: 55px;
        line-height: 30px;
      }
    }
  }
  &__play {
    @media #{$xs} {
      margin-top: 30px;
    }
    &-btn {
      display: inline-block;
      width: 90px;
      height: 90px;
      line-height: 86px;
      text-align: center;
      background: transparent;
      font-size: 25px;
      color: $white;
      border: 2px solid rgba($color: $white, $alpha: 0.2);
      @include border-radius(50%);
      animation: pulse 2s infinite;
      &:hover {
        background: $white;
        color: $black;
        border-color: $white;
      }
    }
  }
  &__shape {
    & img {
      position: absolute;
      &.triangle {
        right: 28%;
        bottom: 38%;
        animation: triangle 5s linear 0s infinite alternate;
        -webkit-animation: triangle 5s linear 0s infinite alternate;
      }
      &.dotted-square {
        right: 22%;
        bottom: 31%;
        animation: dottedSquare 8s linear 0s infinite alternate;
        -webkit-animation: dottedSquare 8s linear 0s infinite alternate;
      }
      &.solid-square {
        bottom: 19%;
        right: 25%;
        z-index: 1;
        animation: solidsquare 5s linear 0s infinite alternate;
        -webkit-animation: solidsquare 5s linear 0s infinite alternate;
      }
      &.circle {
        right: 10%;
        bottom: -47%;
        animation: sliderCircle 7s linear 0s infinite alternate;
        -webkit-animation: sliderCircle 7s linear 0s infinite alternate;
      }
      &.circle-2 {
        right: 12%;
        top: 65%;
        animation: sliderCircle 7s linear 0s infinite alternate;
        -webkit-animation: sliderCircle 7s linear 0s infinite alternate;
      }
    }
    &-1 {
      top: -120px;
      left: -100px;
      z-index: -1;
    }
  }
}

/* slider shapw keyframe */

@keyframes triangle {
  0% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
}

@keyframes dottedSquare {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@keyframes solidsquare {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
  25% {
    transform: translateX(50px);
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
  }

  100% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
  }
}
@keyframes sliderCircle {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

/* pulse btn */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.home_slider_1 {
  background: url(../img/slider/slider-1.jpg);
  background-repeat: no-repeat;
}
.home_slider_2 {
  background: url(../img/slider/slider-2.jpg);
  background-repeat: no-repeat;
}
.h1_slider_wrapper {
  position: relative;
  z-index: 99;
}

/*fadein custom*/
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}

.slick-active span,
.slick-active h1,
.slick-active .slider__btn,
.slick-active .hero-slider-btn,
.slick-active .h4-span {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.slick-active span {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.slick-active h1 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.slick-active .slider__btn {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.hero__content {
  & span {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
  }
  & h1 {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  & p {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
  }
  & a {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
  }
}
